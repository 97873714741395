import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'
import NavigationLink from '../../../components/NavigationLink'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Werkstudierende im Bereich Grafikdesign (m/w/d)"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt für einen Platz als Werkstudierende und sammle wertvolle Erfahrungen, während Du gleichzeitig Deine Finanzen aufbesserst!"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du hast ein Gespür für Ästhetik und liebst es, zu gestalten? Du möchtest in einem kleinen Team Dein volles Potenzial ausschöpfen und Deine Erfahrungen ausweiten? Dann bist Du bei BUSCHMAIS genau richtig. </BigTitle>

      <DefaultSection>
        <Article>
          <h1>Werkstudierende im Bereich Grafikdesign (m/w/d)</h1>
          <br/>
          <h2>Deine Aufgabe</h2>
          <p>Mit Deiner kreativen Ader und Deiner Kommunikationsstärke unterstützt Du uns vorrangig in der Erstellung
            von Grafiken, Druckprodukten und Social Media Bildern.</p>
          <p>Neben der Tätigkeit bei BUSCHMAIS wirst Du mit der Betreuung des <NavigationLink
            to={'https://jugsaxony.org'} ariaLabel={'Webseite des JUG Saxony e. V.'} asTextLink>JUG Saxony e.
            V.</NavigationLink> zu
            tun haben, der sich zu einer unverzichtbaren Plattform für die IT-Community in Sachsen entwickelt hat und u.
            a. die größte sächsische IT-Community-Konferenz, den <NavigationLink to={'https://jugsaxony.day'}
                                                                                 ariaLabel={'Webseite des JUG Saxony Day'}
                                                                                 asTextLink>JUG Saxony
              Day</NavigationLink>, organisiert.
          </p>
          <p>Idealerweise steigst Du bei uns in den Semesterferien ein, so dass wir Dich innerhalb von zwei Wochen mit
            je 40 h perfekt in Dein Projekt einarbeiten können. Anschließend kannst Du bei uns 8 bis 20 h pro Woche
            arbeiten, in
            der vorlesungsfreien Zeit gerne bis zu 40 h pro Woche. </p>
          <p>Wir sind an einer langfristigen Zusammenarbeit interessiert. Daher wäre es ideal, wenn Du uns für mind. 1
            Jahr zur Verfügung stehen kannst.
          </p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Du hast gute Noten in einem Design-Studiengang oder ähnlichem Studiengang.</li>
              <li>Du konntest bereits Erfahrungen mit der Adobe Creative Cloud bzw. Affinity Suite sammeln.</li>
              <li>Du hast sehr gute Deutschkenntnisse (mind. C1 Niveau) und gute Englischkenntnisse.</li>
              <li>Du arbeitest selbstständig und im Team.</li>
              <li>Du bist ein kommunikativer und kreativer Mensch, der gerne auch mal Neues ausprobiert und über den
                Tellerrand blickt.
              </li>
            </ul>
          </p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Wir legen viel Wert auf die Vereinbarkeit von Privat- und Berufsleben und wissen, dass Dein Studium
                natürlich vorgeht.
              </li>
              <li>Uns ist eine direkte Kommunikation enorm wichtig und wir halten die Entscheidungswege so kurz wie nur
                möglich.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten regelmäßig Teamausflüge und Grillabende.
              </li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Sende uns ganz unkompliziert eine E-Mail mit Deinen Vorstellungen oder rufe uns an. Für
            Rückfragen stehen wir Dir gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
